import { mobile, styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Stack } from '@obvio/ui'
import { useMemo } from 'react'

import { CenteredSection } from '../CenteredSection'

import type { AllowUndefinedForOptional } from '@obvio/utils'
import type { ReactElement } from 'react'

const Title = styled.span`
  ${(theme) => theme.typography.h2?.css};
  margin-bottom: ${(theme) => theme.spacing.large};
`

const HeadingSection = styled(CenteredSection)`
  text-align: center;
  padding-bottom: 0 !important;
  width: 46.5624rem !important;
  margin-top: calc(${(theme) => theme.spacing.extraLarge} * 1.25);

  @media ${mobile} {
    max-width: calc(100% - 5rem) !important;
  }
  h2 {
    ${(theme) => theme.typography.h3?.css};
  }

  h3:not(:first-of-type) {
    margin-top: 0 !important;
  }

  h3 {
    font-family: ${(theme) => theme.secondaryFont};
  }
  h3 > b {
    font-weight: 400;
    font-family: ${(theme) => theme.font};
  }
`

type HeadingProps = AllowUndefinedForOptional<{
  heading?: string
  description?: string
  noSection?: boolean
}>

// TODO: Could definitely be improved with Typography component
export function Heading({
  heading,
  description,
  noSection,
}: HeadingProps): ReactElement {
  const content = useMemo(
    () => (
      <Stack kind="vertical" spacing="big">
        {heading ? <Title>{heading}</Title> : null}
        {description ? (
          // <div>
          <RichText value={description} />
        ) : // </div>
        null}
      </Stack>
    ),
    [description, heading],
  )
  if (noSection) {
    return content
  }
  return <HeadingSection>{content}</HeadingSection>
}
